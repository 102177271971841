import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import StylesProvider from '@mui/styles/StylesProvider';
import { BusinessUnitKeyType, RouteName, AppRoutes as Routes } from 'common';
import { Suspense, lazy, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { CustomGlobalStyles } from '../CustomGlobalStyles';
import { CfPageLoader } from '../cf-ui/Loader/PageLoader/CfPageLoader';
import Communications from '../features/account/views/Communications';
import { getTheme } from '../theme/theme';
import { ProtectedRoute } from './components/ProtectedRoute';
import { TitledRoute } from './components/TitledRouted';

const Home = lazy(() => import('../features/site/views/Home'));
const GetStarted = lazy(() => import('../features/site/views/GetStarted'));

const TermsOfService = lazy(() => import('../features/user-acceptance/views/TermsOfService'));
const Cookies = lazy(() => import('../features/user-acceptance/views/Cookies'));
const CustomerSupport = lazy(() => import('../features/account/views/CustomerSupport'));
const ManageUsers = lazy(() => import('../features/account/views/ManageUsers'));
const ManageUsersExport = lazy(() => import('../features/account/views/ManageUsersExport/ManageUsersExport'));
const DeliveryDetails = lazy(() => import('../features/deliveries/views/DeliveryDetails/DeliveryDetails'));
const ProductList = lazy(() => import('../features/product-list-management/views/ProductList/ProductList'));
const ProductListCatalogSearch = lazy(
  () => import('../features/product-list-management/views/ProductListCatalogSearch/ProductListCatalogSearch')
);
const NoUserInformation = lazy(() => import('../features/user-acceptance/views/NoUserInformation'));
const Invoices = lazy(() => import('../features/finances/views/Invoices'));
const ProductListManagement = lazy(() => import('../features/product-list-management/views/ProductListManagement'));
const ProductListNotifications = lazy(
  () => import('../features/product-list-management/views/ProductListNotifications/ProductListNotifications')
);
const ProductListImport = lazy(
  () => import('../features/product-list-management/views/ProductListImport/ProductListImport')
);
const ProductListReport = lazy(
  () => import('../features/product-list-management/views/ProductListExport/ProductListExport')
);
const ProductListDiary = lazy(
  () => import('../features/product-list-management/views/ProductListDiary/ProductListDiary')
);
const OrderImport = lazy(() => import('../features/orders/views/OrderImport/OrderImport'));
const OrderDetails = lazy(() => import('../features/orders/views/OrderDetails/OrderDetails'));
const OrderEntry = lazy(() => import('../features/orders/views/OrderEntry/OrderEntry'));
const OrderReview = lazy(() => import('../features/orders/views/OrderReview/OrderReview'));
const OrderHistory = lazy(() => import('../features/orders/views/Orders/OrderHistory'));
const Search = lazy(() => import('../features/orders/views/CatalogSearch/CatalogSearch'));
const SearchSimilar = lazy(() => import('../features/orders/views/FindSimilar/SimilarProductSearch'));
const OrderConfirmation = lazy(() => import('../features/orders/views/OrderConfirmation/OrderConfirmation'));
const QuickView = lazy(() => import('../features/orders/views/ProductDetails/ProductDetails'));
const SearchAnalysis = lazy(() => import('../features/_experimental/views/SearchAnalysis/SearchAnalysis'));
const UI = lazy(() => import('../features/_experimental/views/UI'));
const VideoUI = lazy(() => import('../features/_experimental/views/Video/VideoUI'));
const UserProfile = lazy(() => import('../features/account/views/UserProfile'));
const CreateMessage = lazy(() => import('../features/account/views/CreateMessage'));
const MessageGroups = lazy(() => import('../features/account/views/MessageGroups'));
const Messages = lazy(() => import('../features/account/views/Messages'));
const CustomerGroups = lazy(() => import('../features/account/views/CustomerGroups'));

const VerifyContactEmail = lazy(() => import('../features/account/views/VerifyContactEmail'));
const LinkExpired = lazy(() => import('../features/site/views/LinkExpired'));
const InvoiceDetail = lazy(() => import('../features/finances/views/InvoiceDetail'));
const Insights = lazy(() => import('../features/finances/views/Insights'));
const InsightsReport = lazy(() => import('../features/finances/views/InsightsReport'));
const ADB2CLoginRedirect = lazy(() => import('./ADB2CLoginRedirect'));
const Dnd = lazy(() => import('../features/_experimental/views/Dnd/Dnd'));
const AccountReceivableStatements = lazy(
  () => import('../features/finances/views/AccountsReceivableStatements/AccountsReceivableStatements')
);
const AddFromList = lazy(() => import('../features/product-list-management/views/AddFromList'));
const ParManagement = lazy(() => import('../features/par-management/views/ParManagement'));
const ParMaintenance = lazy(() => import('../features/par-management/views/ParMaintenance'));
const VendorManagement = lazy(() => import('../features/vendor-management/views/VendorManagement'));
const VendorDetails = lazy(() => import('../features/vendor-management/views/VendorDetailsView'));
const ProductDetailsView = lazy(
  () => import('../features/vendor-management/third-party-product-management/views/ProductDetailsView')
);

export const AppRouting = (): JSX.Element => {
  return (
    <Switch>
      <TitledRoute route={RouteName.Home} path={getPath(RouteName.Home)} component={Home} exact />

      {/* Search */}
      <TitledRoute route={RouteName.ProductSearch} path={getPath(RouteName.ProductSearch)} component={Search} />
      <TitledRoute
        route={RouteName.SimilarProductSearch}
        path={getPath(RouteName.SimilarProductSearch)}
        component={SearchSimilar}
      />
      <TitledRoute route={RouteName.ProductDetails} path={getPath(RouteName.ProductDetails)} component={QuickView} />

      {/* User */}
      <TitledRoute
        route={RouteName.TermsOfService}
        path={getPath(RouteName.TermsOfService)}
        component={TermsOfService}
      />
      <TitledRoute route={RouteName.CookieAcceptance} path={getPath(RouteName.CookieAcceptance)} component={Cookies} />
      <TitledRoute
        route={RouteName.NoUserInformation}
        path={getPath(RouteName.NoUserInformation)}
        component={NoUserInformation}
        exact
      />
      <TitledRoute
        route={RouteName.VerifyContactEmail}
        exact
        path={getPath(RouteName.VerifyContactEmail)}
        component={VerifyContactEmail}
      />
      <TitledRoute route={RouteName.UserProfile} path={getPath(RouteName.UserProfile)} component={UserProfile} />

      {/* Orders */}
      <ProtectedRoute route={RouteName.OrderHistory} path={getPath(RouteName.OrderHistory)} component={OrderHistory} />
      <ProtectedRoute route={RouteName.RepeatOrder} path={getPath(RouteName.RepeatOrder)} component={OrderHistory} />

      <ProtectedRoute route={RouteName.OrderEntry} path={getPath(RouteName.OrderEntry)} component={OrderEntry} />
      <ProtectedRoute
        route={RouteName.OrderDetails}
        path={getPath(RouteName.OrderDetails)}
        component={OrderDetails}
        exact
      />
      <ProtectedRoute route={RouteName.OrderReview} path={getPath(RouteName.OrderReview)} component={OrderReview} />
      <ProtectedRoute
        route={RouteName.OrderConfirmation}
        path={getPath(RouteName.OrderConfirmation)}
        component={OrderConfirmation}
      />
      <ProtectedRoute
        route={RouteName.DeliveryDetails}
        path={getPath(RouteName.DeliveryDetails)}
        component={DeliveryDetails}
        exact
      />
      <ProtectedRoute route={RouteName.OrderImport} path={getPath(RouteName.OrderImport)} component={OrderImport} />

      {/* Message Center */}
      <ProtectedRoute
        route={RouteName.CreateMessage}
        path={Routes[RouteName.CreateMessage].Path}
        component={CreateMessage}
      />
      <TitledRoute
        route={RouteName.ViewInboxMessage}
        path={Routes[RouteName.ViewInboxMessage].Path}
        component={Messages}
      />
      <TitledRoute route={RouteName.InboxMessages} path={Routes[RouteName.InboxMessages].Path} component={Messages} />

      <ProtectedRoute
        route={RouteName.ViewDraftMessage}
        path={Routes[RouteName.ViewDraftMessage].Path}
        component={Messages}
      />
      <ProtectedRoute
        route={RouteName.DraftMessages}
        path={Routes[RouteName.DraftMessages].Path}
        component={Messages}
      />

      <ProtectedRoute
        route={RouteName.ViewSentMessage}
        path={Routes[RouteName.ViewSentMessage].Path}
        component={Messages}
      />
      <ProtectedRoute route={RouteName.SentMessages} path={Routes[RouteName.SentMessages].Path} component={Messages} />

      <ProtectedRoute
        route={RouteName.UpdateMessageGroup}
        path={Routes[RouteName.UpdateMessageGroup].Path}
        component={MessageGroups}
      />
      <ProtectedRoute
        route={RouteName.CreateMessageGroup}
        path={Routes[RouteName.CreateMessageGroup].Path}
        component={MessageGroups}
      />
      <ProtectedRoute
        route={RouteName.ViewMessageGroup}
        path={Routes[RouteName.ViewMessageGroup].Path}
        component={MessageGroups}
      />

      <ProtectedRoute
        route={RouteName.MessageGroups}
        path={Routes[RouteName.MessageGroups].Path}
        component={MessageGroups}
      />

      {/* Communications */}
      <TitledRoute
        route={RouteName.Communications}
        path={Routes[RouteName.Communications].Path}
        component={Communications}
      />

      {/* Manage Users */}
      <ProtectedRoute
        route={RouteName.ManageUsersExport}
        path={Routes[RouteName.ManageUsersExport].Path}
        component={ManageUsersExport}
      />
      <ProtectedRoute route={RouteName.ManageUsers} path={Routes[RouteName.ManageUsers].Path} component={ManageUsers} />

      {/* Customer Support */}
      <TitledRoute
        route={RouteName.CustomerSupport}
        path={getPath(RouteName.CustomerSupport)}
        component={CustomerSupport}
        exact
      />
      <TitledRoute
        route={RouteName.AccountTutorials}
        path={getPath(RouteName.AccountTutorials)}
        component={CustomerSupport}
      />
      <TitledRoute
        route={RouteName.OrderingTutorials}
        path={getPath(RouteName.OrderingTutorials)}
        component={CustomerSupport}
      />
      <TitledRoute
        route={RouteName.InvoiceTutorials}
        path={getPath(RouteName.InvoiceTutorials)}
        component={CustomerSupport}
      />
      <TitledRoute
        route={RouteName.ListManagementTutorials}
        path={getPath(RouteName.ListManagementTutorials)}
        component={CustomerSupport}
      />
      <TitledRoute
        route={RouteName.ParManagementTutorials}
        path={getPath(RouteName.ParManagementTutorials)}
        component={CustomerSupport}
      />

      {/* Customer Groups */}
      <ProtectedRoute
        route={RouteName.CreateCustomerGroup}
        path={Routes[RouteName.CreateCustomerGroup].Path}
        component={CustomerGroups}
      />

      <ProtectedRoute
        route={RouteName.ViewCustomerGroup}
        path={Routes[RouteName.ViewCustomerGroup].Path}
        component={CustomerGroups}
      />

      <ProtectedRoute
        route={RouteName.CustomerGroups}
        path={Routes[RouteName.CustomerGroups].Path}
        component={CustomerGroups}
      />

      {/* List Management*/}
      <ProtectedRoute
        route={RouteName.ProductListDiary}
        path={Routes[RouteName.ProductListDiary].Path}
        component={ProductListDiary}
      />
      <ProtectedRoute
        route={RouteName.ProductListExport}
        path={Routes[RouteName.ProductListExport].Path}
        component={ProductListReport}
        exact
      />
      <ProtectedRoute
        route={RouteName.ProductListCatalogSearch}
        path={getPath(RouteName.ProductListCatalogSearch)}
        component={ProductListCatalogSearch}
      />
      <ProtectedRoute route={RouteName.AddFromList} path={getPath(RouteName.AddFromList)} component={AddFromList} />
      <ProtectedRoute route={RouteName.ProductList} path={getPath(RouteName.ProductList)} component={ProductList} />
      <ProtectedRoute
        route={RouteName.ProductListImport}
        path={getPath(RouteName.ProductListImport)}
        component={ProductListImport}
        exact={true}
      />
      <ProtectedRoute
        route={RouteName.ProductListManagement}
        path={getPath(RouteName.ProductListManagement)}
        component={ProductListManagement}
      />
      <ProtectedRoute
        route={RouteName.ProductListNotifications}
        path={getPath(RouteName.ProductListNotifications)}
        component={ProductListNotifications}
      />

      {/* Finance */}
      <ProtectedRoute
        route={RouteName.AccountsReceivables}
        path={getPath(RouteName.AccountsReceivables)}
        component={AccountReceivableStatements}
        exact
      />
      <ProtectedRoute route={RouteName.Invoices} path={getPath(RouteName.Invoices)} component={Invoices} />
      <ProtectedRoute
        route={RouteName.InvoiceDetail}
        exact
        path={getPath(RouteName.InvoiceDetail)}
        component={InvoiceDetail}
      />
      <ProtectedRoute route={RouteName.Insights} exact path={getPath(RouteName.Insights)} component={Insights} />
      <ProtectedRoute
        route={RouteName.InsightsReport}
        path={getPath(RouteName.InsightsReport)}
        component={InsightsReport}
      />

      {/* Par Management */}
      <ProtectedRoute
        route={RouteName.ParManagement}
        path={getPath(RouteName.ParManagement)}
        component={ParManagement}
        exact
      />
      <ProtectedRoute
        route={RouteName.ParMaintenance}
        path={getPath(RouteName.ParMaintenance)}
        component={ParMaintenance}
      />

      {/* Vendor Product Details */}
      <ProtectedRoute
        route={RouteName.VendorProductDetail}
        path={getPath(RouteName.VendorProductDetail)}
        exact={true}
        component={ProductDetailsView}
      />

      {/* Vendor Management */}
      <ProtectedRoute
        route={RouteName.VendorManagement}
        path={getPath(RouteName.VendorManagement)}
        exact={true}
        component={VendorManagement}
      />

      {/* Vendor Details */}
      <ProtectedRoute
        route={RouteName.VendorDetails}
        path={getPath(RouteName.VendorDetails)}
        component={VendorDetails}
      />

      {/* Landing pages */}
      <TitledRoute route={RouteName.GetStarted} path={getPath(RouteName.GetStarted)} component={GetStarted} />

      {/* Enviornment Related */}
      {process.env.REACT_APP_ENV_NAME !== 'prod' && (
        <TitledRoute
          route={RouteName.SearchAnalysis}
          path={getPath(RouteName.SearchAnalysis)}
          component={SearchAnalysis}
        />
      )}
      {(process.env.REACT_APP_ENV_NAME === 'local' || process.env.REACT_APP_ENV_NAME === 'dev') && (
        <TitledRoute route={RouteName.Video} path={getPath(RouteName.Video)} component={VideoUI} />
      )}
      {(process.env.REACT_APP_ENV_NAME === 'local' || process.env.REACT_APP_ENV_NAME === 'dev') && (
        <TitledRoute route={RouteName.UI} path={getPath(RouteName.UI)} component={UI} />
      )}
      {(process.env.REACT_APP_ENV_NAME === 'local' || process.env.REACT_APP_ENV_NAME === 'dev') && (
        <TitledRoute route={RouteName.DND} path={getPath(RouteName.DND)} component={Dnd} />
      )}
    </Switch>
  );
};

export const UnauthenticatedAppRouting = (): JSX.Element => {
  const [theme, setTheme] = useState<Theme | undefined>(undefined);

  useEffect(() => {
    setTheme(getTheme(BusinessUnitKeyType.PerformanceFoodService));
  }, []);

  return (
    <StylesProvider injectFirst>
      {theme && (
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <CustomGlobalStyles />
            <Suspense fallback={<CfPageLoader isLoading />}>
              <Switch>
                <TitledRoute
                  route={RouteName.GetStarted}
                  path={Routes[RouteName.GetStarted].Path}
                  component={GetStarted}
                />
                <TitledRoute
                  route={RouteName.VerifyContactEmail}
                  exact
                  path={Routes[RouteName.VerifyContactEmail].Path}
                  component={VerifyContactEmail}
                />
                <TitledRoute
                  route={RouteName.LinkExpired}
                  path={Routes[RouteName.LinkExpired].Path}
                  component={LinkExpired}
                />
                <TitledRoute
                  route={RouteName.Login}
                  path={Routes[RouteName.Login].Path}
                  component={ADB2CLoginRedirect}
                />
              </Switch>
            </Suspense>
          </MuiThemeProvider>
        </StyledEngineProvider>
      )}
    </StylesProvider>
  );
};

const getPath = (route: RouteName) => {
  const r = Routes[route];
  let result = r.Path;
  if (r.Params?.length ?? 0 > 0) result = `${Routes[route].Path}/${Routes[route].Params?.join('/')}`;

  return result;
};
