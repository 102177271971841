import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  VendorCreateAndUpdateRequest,
  VendorDeleteRequest,
  VendorRequest,
} from '../../api/models/vendor-list-detail-models';
import VendorManagementService from '../../api/services/vendor-management.service';
import { useAppInsightsLogger } from '../../logging/index';
import { Resolution, UserActivityAction, UserActivityActionSummary, UserActivityPageName } from '../../models';
import { globalSlice, logUserActivity } from '../common/index';
import { AppDispatch, AppThunk, RootState } from '../store';
import { vendorManagementSlice } from './vendor-management.slice';

const appInsightsLogger = useAppInsightsLogger();
const vendorManagementService = VendorManagementService.getInstance();

export const getVendorList =
  (customerId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().vendorManagement.isLoadingVendorList) {
        dispatch(vendorManagementSlice.actions.setIsLoadingVendorList(true));
      }

      const request = { customerId: customerId };
      const { data } = await vendorManagementService.getVendorList(request);

      if (data.IsSuccess) {
        dispatch(vendorManagementSlice.actions.setVendorList(data.ResultObject));
      } else {
        dispatch(vendorManagementSlice.actions.resetVendorList());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    } finally {
      dispatch(vendorManagementSlice.actions.setIsLoadingVendorList(false));
    }
  };

export const getVendor =
  (customerId: string, thirdPartyVendorId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().vendorManagement.isLoadingVendor) {
        dispatch(vendorManagementSlice.actions.setIsLoadingVendor(true));
      }

      const request: VendorRequest = {
        customerId: customerId,
        thirdPartyVendorId: thirdPartyVendorId,
      };

      const { data } = await vendorManagementService.getVendor(request);

      if (data.IsSuccess) {
        dispatch(vendorManagementSlice.actions.setVendor(data.ResultObject));
      } else {
        dispatch(vendorManagementSlice.actions.resetVendor());
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    } finally {
      dispatch(vendorManagementSlice.actions.setIsLoadingVendor(false));
    }
  };

export const createOrUpdateVendor =
  (
    request: VendorCreateAndUpdateRequest,
    resolution: Resolution,
    successCallback?: (thirdPartyVendorId: string) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().vendorManagement.isCreateOrUpdateVendorLoading) {
        dispatch(vendorManagementSlice.actions.setIsCreateOrUpdateVendorLoading(true));
      }

      let response;
      if (request.ThirdPartyVendorId) {
        response = await vendorManagementService.updateVendor(request);
      } else {
        response = await vendorManagementService.createVendor(request);
      }
      const { data } = response;

      if (data.IsSuccess) {
        dispatch(vendorManagementSlice.actions.setIsCreateOrUpdateVendorLoading(false));
        successCallback?.(response?.data.ResultObject.ThirdPartyVendorId);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    } finally {
      dispatch(vendorManagementSlice.actions.setIsCreateOrUpdateVendorLoading(false));
    }
  };

export const deleteVendor =
  (
    customerId: string,
    thirdPartyVendorId: string,
    resolution: Resolution,
    successCallback?: () => void,
    errorCallback?: (errors: string[]) => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const request: VendorDeleteRequest = {
        CustomerId: customerId,
        ThirdPartyVendorId: thirdPartyVendorId,
      };

      if (!getState().vendorManagement.isDeleteVendorLoading) {
        dispatch(vendorManagementSlice.actions.setIsDeleteVendorLoading(true));
      }

      dispatch(
        logUserActivity({
          action: UserActivityAction.DeleteVendor,
          pageName: UserActivityPageName.VendorManagement,
          actionSummary: UserActivityActionSummary.DeletedVendor,
          resolution: resolution,
        })
      );

      const { data } = await vendorManagementService.deleteVendor(request);
      if (data.IsSuccess) {
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      console.error(error);
    } finally {
      dispatch(vendorManagementSlice.actions.setIsDeleteVendorLoading(false));
    }
  };

/**
 * Sets all values in the vendor managment vendor slice to their initial values
 *
 * @returns NULL
 */
export const resetVendor = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(vendorManagementSlice.actions.resetVendor());
};
