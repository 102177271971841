import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { UnitOfMeasures, VendorProduct, VendorProductListSortOption } from '../../api/models/vendor-products.models';
import { normalizeKey } from '../../helpers';
import { RootState } from '../store';

const vendorProductAdapter = createEntityAdapter<VendorProduct>({
  selectId: (product: VendorProduct) => normalizeKey(product.ThirdPartyVendorProductId),
});

// State
interface VendorProductState {
  isLoadingVendorProductList: boolean;
  isCreateOrUpdateProductLoading: boolean;
  vendorProductList: VendorProduct[];
  product: VendorProduct | undefined;
  vendorProductManage: EntityState<VendorProduct>;
  isLoadingProduct: boolean;
  uoms: UnitOfMeasures[];
  sortByOptions: VendorProductListSortOption[];
  isDeleteProductLoading: boolean;
}

const initialState: VendorProductState = {
  isLoadingVendorProductList: false,
  vendorProductList: [],
  product: undefined,
  isCreateOrUpdateProductLoading: false,
  isLoadingProduct: false,
  uoms: [],
  vendorProductManage: vendorProductAdapter.getInitialState(),
  isDeleteProductLoading: false,
  sortByOptions: [
    {
      Title: 'Alphabetical',
      Value: 0,
    },
    {
      Title: 'Product number',
      Value: 1,
    },
  ],
};

// Reducers
export const vendorProductSlice = createSlice({
  name: 'vendorProduct',
  initialState: initialState,
  reducers: {
    setIsLoadingVendorList: (state: VendorProductState, action: PayloadAction<boolean>) => {
      state.isLoadingVendorProductList = action.payload;
    },
    setVendorProductList: (state: VendorProductState, action: PayloadAction<VendorProduct[]>) => {
      state.vendorProductList = action.payload;
    },
    resetVendorList: (state: VendorProductState) => {
      state.vendorProductList = initialState.vendorProductList;
      state.isLoadingVendorProductList = initialState.isLoadingVendorProductList;
    },
    setVendorProductListAdapter: (state: VendorProductState, action: PayloadAction<VendorProduct[]>) => {
      vendorProductAdapter.setAll(state.vendorProductManage, action.payload);
    },
    setProduct: (state: VendorProductState, action: PayloadAction<VendorProduct>) => {
      state.product = action.payload;
    },
    resetProduct: (state: VendorProductState) => {
      state.product = initialState.product;
      state.isLoadingProduct = initialState.isLoadingProduct;
    },
    setIsDeleteProductLoading: (state: VendorProductState, action: PayloadAction<boolean>) => {
      state.isDeleteProductLoading = action.payload;
    },
    setIsCreateOrUpdateProductLoading: (state: VendorProductState, action: PayloadAction<boolean>) => {
      state.isCreateOrUpdateProductLoading = action.payload;
    },
    setUOMs: (state: VendorProductState, action: PayloadAction<UnitOfMeasures[]>) => {
      state.uoms = action.payload;
    },
    resetUOMs: (state: VendorProductState) => {
      state.uoms = initialState.uoms;
    },
  },
});

export const { selectAll: selectAllVendorProducts } = vendorProductAdapter.getSelectors<RootState>(
  (state: RootState) => state.vendorProductSlice.vendorProductManage
);

export const selectAllVendorProductIds = createSelector(
  (state: RootState) => state.vendorProductSlice.vendorProductList,
  (vendorProducts) => {
    return vendorProducts.map((i) => i.ThirdPartyVendorProductId.toString()) as string[];
  }
);

export const { selectAll: selectAllThirdVendorProductIds, selectById: selectVendorProductsId } =
  vendorProductAdapter.getSelectors<EntityState<VendorProduct>>((products: EntityState<VendorProduct>) => products);
